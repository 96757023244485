import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Label,
  Grid,
  Form,
  Segment,
  Checkbox,
  Button,
  Modal,
  Header,
  Icon,
} from 'semantic-ui-react'
import { updateGeneralFlatsInfo } from 'ducks/deviceActions'

const center = {
  display: 'flex',
  justifyContent: 'center',
}

class Switches extends Component {
  state = {
    light: this.props.data.light,
    IR: this.props.data.infrared,
    collectingKeys: this.props.data.configShadow?.collectingKeyStatus !== "OFF",
    modified: false,
    modifiedCollectingKeys: false,
    eraseKeys: null,
    confirmModalOpen: false,
  }

  onLightToggle = (e) =>
    this.setState({ light: !this.state.light, modified: true })

  onIRToggle = (e) => this.setState({ IR: !this.state.IR, modified: true })

  handleModalOpen = () => this.setState({ confirmModalOpen: true })

  handleModalClose = () => this.setState({ confirmModalOpen: false })

  collectingKeys = (e) =>
    this.setState({
      collectingKeys: !this.state.collectingKeys,
      modified: true,
      modifiedCollectingKeys: true,
    })

  onSubmit = (
    e,
    element,
    ignoreModifiedCollectingKeysCheck = false,
    eraseKeys,
  ) => {
    console.log(
      ignoreModifiedCollectingKeysCheck,
      !ignoreModifiedCollectingKeysCheck,
      this.state.modifiedCollectingKeys,
    )
    if (
      !ignoreModifiedCollectingKeysCheck &&
      this.state.modifiedCollectingKeys &&
      this.state.collectingKeys === true
    ) {
      return this.handleModalOpen()
    }
    this.props.updateGeneralFlatsInfo({
      id: this.props.device_id,
      collectingKeys: this.state.collectingKeys,
      light: this.state.light,
      infrared: this.state.IR,
      eraseKeys,
    })
    this.setState({
      modified: false,
      modifiedCollectingKeys: false,
      confirmModalOpen: false,
    })
  }

  render() {
    const { style, ...props } = this.props
    return (
      <Segment
        style={{ display: 'flex', flexDirection: 'column', ...style }}
        {...props}
      >
        <Modal
          // trigger={<Button onClick={this.handleModalOpen}>Show Modal</Button>}
          open={this.state.confirmModalOpen}
          onClose={this.handleModalClose}
          basic
          size="small"
        >
          <Header icon="warning" content="Внимание" />
          <Modal.Content>
            <h3>
              При включенном «Автосбор» все поднесенные ключи записываются в
              базу и будут добавлены к уже существующему списку после отключения
              режима.
            </h3>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.handleModalClose} inverted circular>
              Отмена
            </Button>
            <Button
              onClick={() => this.onSubmit(null, null, true, false)}
              inverted
              circular
            >
              <Icon name="save" /> Сохранить существующие ключи
            </Button>
            <Button
              color="red"
              onClick={() => this.onSubmit(null, null, true, true)}
              inverted
              circular
            >
              <Icon name="eraser" /> Стереть ключи
            </Button>
          </Modal.Actions>
        </Modal>
        {/* <div> */}
        {/* <Label color="black" attached="top" style={center}> */}
        <Header>Взаимодействие с панелью</Header>
        {/* </Label> */}
        {/* <div
          style={{
            ...center,
            flexDirection: 'column',
            // marginTop: '1rem',
            flex: 1,
          }}
        > */}
        <Form
          style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
          // style={{ display: 'flex', justifyContent: 'space-evenly' }}
        >
          {/* <Form.Group
            widths="equa
        l"
          > */}
          <Form.Field>
            <Checkbox
              label="Подсветка"
              checked={this.state.light ? true : false}
              toggle
              onClick={this.onLightToggle}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label="ИК подсветка"
              toggle
              onClick={this.onIRToggle}
              checked={this.state.IR ? true : false}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label="Автосбор"
              checked={this.state.collectingKeys ? true : false}
              toggle
              onClick={this.collectingKeys}
            />
          </Form.Field>
          {/* </Form.Group> */}

          <Form.Button
            circular
            // style={{ margin: '0 auto', marginTop: 'auto' }}
            color="black"
            onClick={this.onSubmit}
            disabled={!this.state.modified}
            className="update-button"
          >
            Обновить
          </Form.Button>
        </Form>
      </Segment>
    )
  }
}

const mapStateToProps = (state) => ({
  data: state.device.currentDevice.configShadow,
  device_id: state.device.currentDevice.uuid,
})

export default connect(mapStateToProps, { updateGeneralFlatsInfo })(Switches)
