import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import debounce from 'lodash/debounce'
import {
  Container,
  Grid,
  Header,
  List,
  Loader,
  Pagination,
  Input,
} from 'semantic-ui-react'
import { connect } from 'react-redux'
import { getAllDevices } from '../../ducks/devices'
import DeviceListing from 'components/listings/DeviceListing'
import Navbar from 'components/global/Navbar'

class HomePage extends Component {
  constructor(props) {
    super(props)
    this.handleSearch = debounce(this.handleSearch, 200)
  }

  state = {
    activePage: 1,
    totalPages: 1,
  }

  devicesRef = []

  componentDidMount() {
    this.props.getAllDevices(1, '')
    window.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown = e => {
    const key = e.which || e.keyCode
    if (key === 13) {
      if (this.devicesRef && this.devicesRef.length > 0 && this.devicesRef[0]) {
        ReactDOM.findDOMNode(this.devicesRef[0]).click()
      }

      // this.devicesRef &&
      //   this.devicesRef.length > 0 &&
      //   this.devicesRef[0].click()
    }
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (
  //     prevProps.devices &&
  //     this.props.devices &&
  //     prevProps.devices.length !== this.props.devices.length
  //   ) {
  //     this.devicesRef &&
  //       this.devicesRef.length > 0 &&
  //       ReactDOM.findDOMNode(this.devicesRef[0]).focus()
  //   }
  // }

  handlePaginationChange = (e, { activePage }) => {
    this.props.getAllDevices(activePage, '')
    this.setState({ activePage })
  }

  handleSearch = (e, { value }) => {
    this.props.getAllDevices(1, value)
    this.setState({ activePage: 1 })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.totalPages) {
      return { totalPages: nextProps.totalPages }
    }
    return prevState
  }

  render() {
    const { activePage, totalPages } = this.state

    // if (!this.props.fetched) {
    //   return <Loader active style={{ marginTop: '4.625rem' }} />
    // }

    return (
      <React.Fragment>
        <Navbar />

        <Container>
          <Input
            style={{
              marginTop: '1.625rem',
            }}
            fluid
            icon="search"
            placeholder="Поиск устройств..."
            onChange={this.handleSearch}
            autoFocus
          />
          <Header
            // dividing
            style={{
              // letterSpacing: '.525rem',
              fontSize: '1.325rem',
              marginTop: '1.625rem',
              marginBottom: '1.625rem',
            }}
          >
            <Header.Content>Список устройств</Header.Content>
          </Header>

          <Grid
            style={{
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
            }}
          >
            {this.props.fetching && (
              <div
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  zIndex: 1000,
                  background: 'rgba(255,255,255,0.7)',
                }}
              >
                <Loader active></Loader>
              </div>
            )}
            <Grid.Row style={{ padding: 0 }}>
              {this.props.devices && this.props.devices.length > 0 ? (
                <List relaxed selection size="big" style={{ width: '100%' }}>
                  {this.props.devices.map((device, i) => (
                    <DeviceListing
                      key={device.device_id}
                      id={device.device_id}
                      serialNumber={device.serial_number}
                      ref={n => (this.devicesRef[i] = n)}
                      isOnline={device.is_online}
                      cameraIsOnline={device.camera_is_online}
                      idx={i}
                      motherboardId={device.motherboard_id}
                    />
                  ))}
                </List>
              ) : (
                <div>Устройства не найдены</div>
              )}
            </Grid.Row>
            {this.props.totalPages > 1 && (
              <Grid.Row centered>
                <Pagination
                  activePage={activePage}
                  onPageChange={this.handlePaginationChange}
                  totalPages={totalPages}
                  // boundaryRange={0}
                />
              </Grid.Row>
            )}
          </Grid>
        </Container>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  jwt: state.auth.jwt,
  devices: state.devices.devices,
  totalPages: state.devices.totalPages,
  fetched: state.devices.fetched,
  fetching: state.devices.fetching,
})

export default connect(mapStateToProps, { getAllDevices })(HomePage)
