import axios from 'axios'
import apolloClient from "../gql/client";
import {INTERCOM} from "../gql/query";
import {UPDATE_INTERCOM_NETWORK_CONFIG} from "../gql/mutations";
const BASE_URL = 'https://api.sputnik.systems/api/v1/'

export default class sputnikApi {
  static requestHeaders = () => {
    const jwt = JSON.parse(localStorage.devicesAppState).auth.jwt
    return { Authorization: `Bearer ${jwt}` }
  }

  static validateToken({ jwt }) {
    const url = `${BASE_URL}check_auth/`
    return axios.get(url, {
      headers: { Authorization: `Bearer ${jwt}` },
    })
  }

  static getAllDevices = ({ page, query = '' }) => {
    const url = `${BASE_URL}account/devices/intercoms/?search=${query}&page=${page}`
    return axios.get(url, {
      headers: sputnikApi.requestHeaders(),
    })
  }

  static getDeviceInfo = async (id) => {
    return apolloClient.query({
      query: INTERCOM,
      variables: {input: {uuid: id}, limitFlats: 10000},
      errorPolicy: "ignore"
    })
  }

  static factoryReset = (uuid) => {
    const url = `${BASE_URL}account/devices/intercoms/${uuid}/factory_reset`
    return axios.get(url, {
      headers: sputnikApi.requestHeaders(),
    })
  }

  static getDeviceInfoShadow = (id) => {
    const url = `${BASE_URL}account/devices/intercoms/${id}/shadow` // delte hardcode
    return axios.get(url, {
      headers: sputnikApi.requestHeaders(),
    })
  }

  static updateSIPConfig = ({
    id,
    server,
    username,
    login,
    pass,
    incoming_call,
    call_password,
    dtmf_open_door,
  }) => {
    const url = `${BASE_URL}account/devices/intercoms/${id}`

    let params = {
      sip_server: server,
      sip_username: username,
      sip_login: login,
      incoming_call: incoming_call,
      call_password: call_password || '',
      dtmf_open_door: dtmf_open_door || '',
    }

    if (pass) {
      params.sip_pass = pass
    }

    return axios.patch(url, params, { headers: sputnikApi.requestHeaders() })
  }

  static updateOptionalButtonConfig = ({
    id,
    sip_server,
    sip_call_uri,
    sip_pass,
    sip_login,
    sip_username,
    use_main_sip_credentials,
  }) => {
    const url = `${BASE_URL}account/devices/intercoms/${id}`

    let params = {
      optional_button: {
        sip_server,
        sip_call_uri,
        sip_login,
        sip_username,
        use_main_sip_credentials,
      },
    }
    if (sip_pass) {
      params.optional_button.sip_pass = sip_pass
    }

    return axios.patch(url, params, { headers: sputnikApi.requestHeaders() })
  }

  static updateGeneralFlatsInfo = ({
    id,
    firstFlat,
    lastFlat,
    infrared,
    light,
    collectingKeys,
    bootKey,
    extCom,
    eraseKeys,
    offset,
    default_threshold_call,
    default_threshold_door,
    timeout_flat_call,
    timeout_flat_speak,
  }) => {
    const url = `${BASE_URL}account/devices/intercoms/${id}`
    return axios.patch(
      url,
      {
        first_flat: firstFlat,
        last_flat: lastFlat,
        collecting_keys: collectingKeys,
        infrared: infrared,
        light: light,
        boot_key: bootKey,
        ext_com: extCom,
        erase_keys: eraseKeys,
        flat_offset: offset,
        default_threshold_call,
        default_threshold_door,
        timeout_flat_call,
        timeout_flat_speak,
      },
      { headers: sputnikApi.requestHeaders() }
    )
  }

  static updateDeviceSounds = ({ id, soundType, startTime, endTime, file }) => {
    const url = `${BASE_URL}account/devices/intercoms/${id}/${soundType}`
    let formData = new FormData()

    formData.append('start_date', startTime)
    formData.append('end_date', endTime)
    formData.append('file', file)

    return axios.post(url, formData, {
      headers: sputnikApi.requestHeaders(),
    })
  }

  static updateStandartSounds = ({ id, soundId, file }) => {
    let formData = new FormData()
    formData.append('file', file)
    formData.append('id', soundId)
    const url = `${BASE_URL}account/devices/intercoms/${id}/standard_sounds`
    return axios.post(url, formData, { headers: sputnikApi.requestHeaders() })
  }

  static getDeviceLogs = ({ id, qty, page }) => {
    const url = `${BASE_URL}account/devices/intercoms/${id}/logs?limit=${qty}&page=${page}`
    return axios.get(url, {
      headers: sputnikApi.requestHeaders(),
    })
  }
  static getCameraDeviceLogs = ({ id, qty, page }) => {
    const url = `${BASE_URL}account/devices/cameras/${id}/logs?limit=${qty}&page=${page}`
    return axios.get(url, {
      headers: sputnikApi.requestHeaders(),
    })
  }

  static getDeviceUserLogs = ({ id, qty, page }) => {
    const url = `${BASE_URL}account/devices/intercoms/${id}/user_logs?limit=${qty}&page=${page}`
    return axios.get(url, {
      headers: sputnikApi.requestHeaders(),
    })
  }

  static openDoor = ({ id, duration }) => {
    const url = `${BASE_URL}account/devices/intercoms/${id}/open_door?duration=${duration}`
    return axios.get(url, {
      headers: sputnikApi.requestHeaders(),
    })
  }

  static openSecondDoor = ({ id, duration }) => {
    const url = `${BASE_URL}account/devices/intercoms/${id}/open_second_door?duration=${duration}`
    return axios.get(url, {
      headers: sputnikApi.requestHeaders(),
    })
  }

  static startEmergency = ({
    id,
    file,
    type,
    cycles,
    unlockedTime,
    emergency_id = 1,
  }) => {
    const url = `${BASE_URL}account/devices/intercoms/${id}/emergency`
    let formData = new FormData()

    switch (type) {
      case 'download_sound':
        formData.append('file', file)
        formData.append('type', 'download_sound')
        break
      default:
        if (file) {
          formData.append('file', file)
        }
        formData.append('type', 'start_emergency')
        break
    }
    formData.append('unlock', unlockedTime)
    formData.append('duration', cycles)
    formData.append('emergency_id', emergency_id)

    return axios.post(url, formData, {
      headers: sputnikApi.requestHeaders(),
    })
  }

  static resetIntercom = (id) => {
    const url = `${BASE_URL}account/devices/intercoms/${id}/reset`
    return axios.get(url, {
      headers: sputnikApi.requestHeaders(),
    })
  }

  static uploadSound = ({ title, file }) => {
    const url = `${BASE_URL}account/sounds/`
    let formData = new FormData()
    formData.append('file', file)
    formData.append('title', title)
    return axios.post(url, formData, {
      headers: sputnikApi.requestHeaders(),
    })
  }

  static uploadFirmware = ({ uuid, file, firmwareType }) => {
    let url = `${BASE_URL}account/devices/intercoms/${uuid}/upgrade`

    if (firmwareType === 'bluetooth') {
      url = `${BASE_URL}account/devices/intercoms/${uuid}/upload_bt_firmware`
    }

    let formData = new FormData()
    formData.append('file', file)
    return axios.patch(url, formData, {
      headers: sputnikApi.requestHeaders(),
    })
  }


  static updateSoundConfig = ({ uuid, ...sound_config }) => {
    const url = `${BASE_URL}account/devices/intercoms/${uuid}/change_sound_config`
    return axios.patch(
      url,
      { sound_config },
      { headers: sputnikApi.requestHeaders() }
    )
  }

  static updateNetworkInfo = ({ uuid, networkConfig }) => {
    return apolloClient.mutate({
      mutation: UPDATE_INTERCOM_NETWORK_CONFIG,
      variables: {
        input: {
          intercomID: uuid,
          networkConfig: networkConfig
        }
      }
    })
  }

  static updateTimeZone = ({ uuid, zone }) => {
    const url = `${BASE_URL}account/devices/intercoms/${uuid}`
    return axios.patch(
      url,
      { time_zone: zone },
      { headers: sputnikApi.requestHeaders() }
    )
  }

  static updateDoorOpenTimer = ({
    id,
    door_open_time_general,
    door_open_time_social,
    door_open_time_emergency,
    door_open_time_bt,
    lock_no_nc,
    external_interface_door_ctrl,
  }) => {
    const url = `${BASE_URL}account/devices/intercoms/${id}`
    return axios.patch(
      url,
      {
        door_open_time_general,
        door_open_time_social,
        door_open_time_emergency,
        door_open_time_bt,
        lock_no_nc,
        external_interface_door_ctrl,
      },
      { headers: sputnikApi.requestHeaders() }
    )
  }

  static addKey = ({ uuid, key, file }) => {
    const url = `${BASE_URL}account/devices/intercoms/${uuid}/keys`

    let formData = new FormData()

    if (file) {
      formData.append('file', file)
    } else {
      formData.append('key', key)
    }

    return axios.post(url, formData, { headers: sputnikApi.requestHeaders() })
  }

  static removeKey = ({ uuid, hex }) => {
    const url = `${BASE_URL}account/devices/intercoms/${uuid}/keys/${hex}`
    return axios.delete(url, {
      headers: sputnikApi.requestHeaders(),
    })
  }

  static removeAllKeys = (intercomUuid) => {
    const url = `${BASE_URL}account/devices/intercoms/${intercomUuid}/keys/delete_all`
    return axios.patch(
      url,
      {},
      {
        headers: sputnikApi.requestHeaders(),
      }
    )
  }

  static resetDeviceSounds = ({ id }) => {
    const url = `${BASE_URL}account/devices/intercoms/${id}/reset_standard_sounds`
    return axios.get(url, {
      headers: sputnikApi.requestHeaders(),
    })
  }

  static getDeviceKeysCount = ({ deviceUuid }) => {
    const url = `${BASE_URL}account/devices/intercoms/${deviceUuid}/keys/count`

    return axios.get(url, {
      headers: sputnikApi.requestHeaders(),
    })
  }

  static resetDeviceCamera = ({ uuid }) => {
    const url = `${BASE_URL}account/devices/intercoms/${uuid}/reset_camera`

    return axios.get(url, {
      headers: sputnikApi.requestHeaders(),
    })
  }

  static downloadKeys = ({ uuid }) => {
    const url = `${BASE_URL}account/devices/intercoms/${uuid}/keys/download`
    // 'Content-Disposition: attachment;'
    return axios({
      url,
      method: 'GET',
      responseType: 'blob',
      headers: sputnikApi.requestHeaders(),
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'keys.csv') //or any other extension
      document.body.appendChild(link)
      link.click()
    })
  }

  static getDeviceAccessCodes = ({ deviceUuid }) => {
    const url = `${BASE_URL}account/devices/intercoms/${deviceUuid}/digital_keys`

    return axios.get(url, {
      headers: sputnikApi.requestHeaders(),
    })
  }

  static addAccessCode = ({ deviceUuid, key, expTime, description }) => {
    const url = `${BASE_URL}account/devices/intercoms/${deviceUuid}/digital_keys`

    return axios.post(
      url,
      { key_value: key, exp_time: expTime, description },
      {
        headers: sputnikApi.requestHeaders(),
      }
    )
  }

  static deleteAccessCode = ({ deviceUuid, key }) => {
    const url = `${BASE_URL}account/devices/intercoms/${deviceUuid}/digital_keys/${key}`

    return axios.delete(url, {
      headers: sputnikApi.requestHeaders(),
    })
  }

  static addBLEKey = ({ intercomUuid, key }) => {
    const url = `${BASE_URL}account/devices/intercoms/${intercomUuid}/ble_keys`

    return axios.post(
      url,
      {
        ble_key: key,
      },
      {
        headers: sputnikApi.requestHeaders(),
      }
    )
  }

  static removeBLEKey = ({ intercomUuid, key }) => {
    const url = `${BASE_URL}account/devices/intercoms/${intercomUuid}/ble_keys/${key}`

    return axios.delete(url, {
      headers: sputnikApi.requestHeaders(),
    })
  }

  static getNetInfo = ({ deviceUuid }) => {
    const url = `${BASE_URL}account/devices/intercoms/${deviceUuid}/net_info`

    return axios.get(url, {
      headers: sputnikApi.requestHeaders(),
    })
  }

  static getCameraNetInfo = ({ deviceUuid }) => {
    const url = `${BASE_URL}account/devices/cameras/${deviceUuid}/net_info`

    return axios.get(url, {
      headers: sputnikApi.requestHeaders(),
    })
  }

  static getURLInfo = ({ deviceUuid }) => {
    const url = `${BASE_URL}account/devices/intercoms/${deviceUuid}/url_info`

    return axios.get(url, {
      headers: sputnikApi.requestHeaders(),
    })
  }

  static lineTest = ({ deviceUuid, flat }) => {
    const url = `${BASE_URL}account/devices/intercoms/${deviceUuid}/line_test
`
    return axios.post(
      url,
      {
        flat,
      },
      {
        headers: sputnikApi.requestHeaders(),
      }
    )
  }

  static toggleMode = (deviceUuid) => {
    const url = `${BASE_URL}account/devices/intercoms/${deviceUuid}/toggle_mode`

    return axios.patch(
      url,
      {},
      {
        headers: sputnikApi.requestHeaders(),
      }
    )
  }


  static deleteAllFlats = (deviceUuid) => {
    const url = `${BASE_URL}account/devices/intercoms/${deviceUuid}/delete_all_flats`

    return axios.patch(
      url,
      {},
      {
        headers: sputnikApi.requestHeaders(),
      }
    )
  }

  static deleteAllAccessCodes = (intercomUuid) => {
    const url = `${BASE_URL}account/devices/intercoms/${intercomUuid}/digital_keys/destroy_all`
    return axios.delete(url, {
      headers: sputnikApi.requestHeaders(),
    })
  }

  static updateSecuredKeys = ({ intercomUuid, params }) => {
    const url = `${BASE_URL}account/devices/intercoms/${intercomUuid}`

    return axios.patch(url, params, { headers: sputnikApi.requestHeaders() })
  }

  static fetchKeys = ({ intercomUuid, params }) => {
    const url = `${BASE_URL}account/devices/intercoms/${intercomUuid}/keys?page=${
      params?.page || 1
    }`

    return axios.get(url, { headers: sputnikApi.requestHeaders() })
  }

  static updateKeyComment = ({ intercomUuid, params }) => {
    const url = `${BASE_URL}account/devices/intercoms/${intercomUuid}/keys/${params.key_data}`

    return axios.patch(url, params, { headers: sputnikApi.requestHeaders() })
  }

  static createKey = ({ intercomUuid, params }) => {
    const url = `${BASE_URL}account/devices/intercoms/${intercomUuid}/keys`
    console.log(params)
    return axios.post(
      url,
      {
        key: [params.key_data],
        description: params.description,
      },
      { headers: sputnikApi.requestHeaders() }
    )
  }

  static updateCodeComment = ({ intercomUuid, params }) => {
    const url = `${BASE_URL}account/devices/intercoms/${intercomUuid}/digital_keys/${params.uuid}`

    return axios.patch(
      url,
      {
        description: params.description,
      },
      { headers: sputnikApi.requestHeaders() }
    )
  }

  static uploadKeysButch = ({ intercomUuid, file }) => {
    const url = `${BASE_URL}account/devices/intercoms/${intercomUuid}/keys`

    let formData = new FormData()

    formData.append('file', file)

    return axios.post(url, formData, { headers: sputnikApi.requestHeaders() })
  }

  static uploadCodesButch = ({ intercomUuid, file }) => {
    const url = `${BASE_URL}account/devices/intercoms/${intercomUuid}/digital_keys`

    let formData = new FormData()
    formData.append('file', file)

    return axios.post(url, formData, { headers: sputnikApi.requestHeaders() })
  }

  static downloadCodes = ({ intercomUuid }) => {
    const url = `${BASE_URL}account/devices/intercoms/${intercomUuid}/digital_keys/download`
    // 'Content-Disposition: attachment;'
    return axios({
      url,
      method: 'GET',
      responseType: 'blob',
      headers: sputnikApi.requestHeaders(),
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'keys.csv') //or any other extension
      document.body.appendChild(link)
      link.click()
    })
  }
}
