import { setContext } from '@apollo/client/link/context'
import {
    ApolloClient,
    InMemoryCache,
    createHttpLink,
    from,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import {toastr} from "react-redux-toastr";
const httpLink = createHttpLink({
    uri: 'https://api.sputnik.systems/query',
})

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = JSON.parse(localStorage.getItem('devicesAppState'))
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            'Content-Type': 'application/json',
            ...headers,
            Authorization: token ? `Bearer ${token.auth.jwt}` : '',
        },
    }
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
            toastr.error('Что-то пошло не так...', message)
        )
    if (networkError) console.log(`[Network error]: ${networkError}`)
})

const client = new ApolloClient({
    link: from([errorLink, authLink.concat(httpLink)]),
    cache: new InMemoryCache(),
    defaultOptions: {
        query: {
            fetchPolicy: 'network-only',
        },
    },
})

export default client