import React, { Component } from 'react'
import {
  Grid,
  Dropdown,
  Button,
  Icon,
  Accordion,
  Container,
  Message,
  Form,
} from 'semantic-ui-react'
import Dropzone from 'react-dropzone'
import { connect } from 'react-redux'
import { updateStandartSounds, resetDeviceSounds } from 'ducks/deviceActions'
import styled from 'styled-components'
import wrapCategory from 'components/categories/wrapCategory'
import { teal, lightRed } from 'theme.js'

const Wrapper = styled.div`
  .title {
    &:hover {
      color: white !important;
      background: ${teal};
    }

    &.active {
      color: white !important;
      background: ${teal};
    }
  }

  .content {
    &.active {
      background: ${lightRed};
    }
  }
`
const actionsOptions = [
  {
    key: '1',
    text: '«Выход» Открытие двери из подъезда изнутри(5сек)',
    value: 1,
    duration: 5,
  },
  {
    key: '2',
    text: 'Ключ не верный, несуществующий номер, квартира блокирована(5 сек)',
    value: 2,
    duration: 5,
  },
  { key: '3', text: 'Звонок в 112(5 сек)', value: 3, duration: 5 },
  {
    key: '4',
    text: '«Трель» Звонок в квартиру(5 сек.)',
    value: 15,
    duration: 5,
  },
  {
    key: '5',
    text: '«Гость» При входе гостя в дверь(10 сек)',
    value: 16,
    duration: 10,
  },
  {
    key: '6',
    text: '«Вход» Открытие двери в подъезд по ключу. Обычная трель(5 сек)',
    value: 17,
    duration: 5,
  },
  { key: '7', text: '«Опрос завершен» (10 сек)', value: 18, duration: 10 },
]

class StandardSounds extends Component {
  state = {
    selectedAction: null,
    error: '',
    acceptedFile: [],
  }

  handleActionChange = (e, { value }) =>
    this.setState({ selectedAction: value, error: '' })

  handleSubmit = () => {
    const { acceptedFile, selectedAction } = this.state

    this.props.updateStandartSounds({
      id: this.props.id,
      soundId: selectedAction,
      file: acceptedFile[0],
    })
  }

  onDrop = (accepted, rejected) => {
    this.setState({
      acceptedFile: accepted,
      rejectedFile: rejected,
      fileName: accepted[0].name,
    })
  }

  handleResetSounds = () => {
    this.props.resetDeviceSounds(this.props.id)
  }

  render() {
    const { selectedAction, error } = this.state

    return (
      <Wrapper>
        <Accordion.Title
          active={this.props.activeIndex === 7}
          index={7}
          onClick={this.props.handleClick}
        >
          <Container className="category-title">
            Стандартные звуки
            <Icon
              name={`angle ${this.props.activeIndex === 7 ? 'down' : 'right'}`}
            />
          </Container>
        </Accordion.Title>
        <Accordion.Content active={this.props.activeIndex === 7}>
          <Container>
            <Grid>
              <Grid.Row>
                <Form style={{ width: '100%' }}>
                  <Dropdown
                    value={selectedAction}
                    options={actionsOptions}
                    onChange={this.handleActionChange}
                    placeholder="Действие"
                    className="icon"
                    icon="compass"
                    labeled
                    search
                    button
                    basic
                    fluid
                    style={{ borderRadius: '10rem' }}
                  />
                </Form>
              </Grid.Row>

              <Grid.Row centered>
                <Dropzone
                  accept=".wav,.mp3"
                  style={{
                    display: 'inline-block',
                    marginRight: '.625rem',
                  }}
                  multiple={false}
                  onDrop={this.onDrop}
                >
                  {({ getInputProps, getRootProps }) => (
                    <Button
                      circular
                      icon
                      labelPosition="left"
                      style={{
                        width: '220px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <Icon name="file audio outline" />
                      {this.state.fileName
                        ? this.state.fileName
                        : 'Выбрать звуковой файл'}
                    </Button>
                  )}
                </Dropzone>
              </Grid.Row>

              {error.length > 0 && (
                <Grid.Row>
                  <Grid.Column textAlign="center">
                    <Message size="mini" negative>
                      <p>{this.state.error}</p>
                    </Message>
                  </Grid.Column>
                </Grid.Row>
              )}
              <Grid.Row>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flex: 1,
                  }}
                >
                  <Button
                    basic
                    icon="erase"
                    content="Вернуть стандартные"
                    color="teal"
                    onClick={this.handleResetSounds}
                    circular
                    style={{ marginRight: '.625rem' }}
                  />
                  <Button
                    icon="refresh"
                    content="Обновить"
                    color="teal"
                    onClick={this.handleSubmit}
                    circular
                    disabled={!this.state.acceptedFile[0]}
                  />
                </div>
              </Grid.Row>
            </Grid>
          </Container>
        </Accordion.Content>
      </Wrapper>
    )
  }
}
const mapStateToProps = ({ device: {currentDevice} }) => ({
  sounds: currentDevice,
})

export default connect(
  mapStateToProps,
  { updateStandartSounds, resetDeviceSounds },
)(wrapCategory(StandardSounds))
