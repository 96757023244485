import React, { useState } from 'react'
import {
  Button,
  Accordion,
  Container,
  Icon,
  Table,
  Form,
  Input,
} from 'semantic-ui-react'
import Dropzone from 'react-dropzone'
import Flatpickr from 'react-flatpickr'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import 'flatpickr/dist/themes/light.css'
import styled from 'styled-components'
import useInput from 'hooks/useInput'

const styles = {
  input: { borderRadius: '10rem', margin: 0 },
  form: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '.325rem',
  },
}

const StyledForm = styled(Form)`
  && {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.325rem;
    .field {
      margin: 0;
    }
    input {
      border-radius: 10rem !important;
    }
  }
`

export default function UploadSoundForm({ data, color, onSubmit, ...props }) {
  const [state, setState] = useState({
    startDate: data.start ? new Date(data.start * 1000) : undefined,
    endDate: data.end ? new Date(data.end * 1000) : undefined,
    acceptedFile: [],
  })

  const { value: startTime, bind: bindStartTime } = useInput('')
  const { value: endTime, bind: bindEndTime } = useInput('')

  const handleDrop = (accepted, rejected) => {
    setState({
      ...state,
      acceptedFile: accepted,
      rejectedFile: rejected,
      fileName: accepted[0].name,
    })
  }

  const handleSubmit = () => {
    const [startHours, startMinutes] = startTime.split(':')
    const start = new Date(state.startDate)
    start.setHours(startHours, startMinutes)

    const [endHours, endMinutes] = endTime.split(':')
    const end = new Date(state.endDate)
    end.setHours(endHours, endMinutes)

    onSubmit(start, end, state.acceptedFile)
  }

  return (
    <Table color={color}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Звук</Table.HeaderCell>
          <Table.HeaderCell>Диапазон дат</Table.HeaderCell>
          <Table.HeaderCell>Диапазон времени</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        <Table.Row>
          <Table.Cell width="2">
            <Form size="small">
              <Dropzone
                accept=".wav,.mp3"
                style={{
                  display: 'inline-block',
                  marginRight: '.625rem',
                }}
                multiple={false}
                onDrop={handleDrop}
              >
                {({ getInputProps, getRootProps }) => (
                  <Button
                    basic
                    circular
                    icon
                    labelPosition="left"
                    style={{
                      width: '220px',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <Icon name="file audio outline" />
                    {state.fileName || 'Выбрать звуковой файл'}
                  </Button>
                )}
              </Dropzone>
            </Form>
          </Table.Cell>
          <Table.Cell width="5">
            <StyledForm>
              <Flatpickr
                style={styles.input}
                options={{
                  time_24hr: true,
                  locale: Russian,
                  minDate: '01-01-2018',
                }}
                value={state.startDate}
                onChange={(date) => setState({ ...state, startDate: date[0] })}
              />
              <Flatpickr
                style={{ borderRadius: '10rem' }}
                options={{
                  time_24hr: true,
                  locale: Russian,
                  minDate: '01-01-2018',
                }}
                value={state.endDate}
                onChange={(date) => setState({ ...state, endDate: date[0] })}
              />
            </StyledForm>
          </Table.Cell>
          <Table.Cell width="5">
            <StyledForm>
              <Form.Input
                type="time"
                style={styles.input}
                {...bindStartTime}
              ></Form.Input>
              <Form.Input
                type="time"
                style={styles.input}
                {...bindEndTime}
              ></Form.Input>
            </StyledForm>
          </Table.Cell>
        </Table.Row>
      </Table.Body>

      <Table.Footer fullWidth>
        <Table.Row>
          <Table.HeaderCell colSpan="3">
            <Button
              circular
              onClick={handleSubmit}
              color={color}
              disabled={!state.acceptedFile[0] || !startTime || !endTime}
            >
              Отправить
            </Button>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  )
}
