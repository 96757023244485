import React, { Component } from 'react'
import {
  Accordion,
  Container,
  Icon,
  Form,
  Input,
  Button,
} from 'semantic-ui-react'
import styled from 'styled-components'
import wrapCategory from 'components/categories/wrapCategory'

import { yellow } from 'theme.js'
import {connect, useSelector} from 'react-redux'
import { withRouter } from 'react-router-dom'
import MindMap from '../global/MindMap'

const Wrapper = styled.div`
  .title {
    &:hover {
      color: white !important;
      background: ${yellow};
    }

    &.active {
      color: white !important;
      background: ${yellow};
    }
  }

  .content {
    &.active {
      background: ${yellow};
    }
  }
`

const Cluster = ({ activeIndex, handleClick }) => {
    const device = useSelector(state => state.device.currentDevice)

    return (
      <Wrapper>
        <Accordion.Title
          active={activeIndex === 10}
          index={10}
          onClick={handleClick}
        >
          <Container className="category-title">
            Кластер
            <Icon name={`angle ${activeIndex === 10 ? 'down' : 'right'}`} />
          </Container>
        </Accordion.Title>

        <div
          style={{
            height: activeIndex === 10 ? 500 : 0,
            overflow: 'hidden',
            background: '#fbfbfb',
          }}
        >
          <MindMap data={device}></MindMap>
        </div>
        {/* <Accordion.Content active={activeIndex === 10}></Accordion.Content> */}
      </Wrapper>
    )
}

export default withRouter(wrapCategory(Cluster))
