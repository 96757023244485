import React, { Fragment } from 'react'
import { Menu, Segment } from 'semantic-ui-react'
import { NavLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { logOut } from 'ducks/auth'
import { toastr } from 'react-redux-toastr'
import { formatDeviceId } from 'helpers'

const Navbar = ({ currentDevice, match, logOut, uuid }) => (
  <Segment
    inverted
    style={{
      padding: 0,
      margin: 0,
      textTransform: 'capitalize',
      borderRadius: 0,
      posisition: 'relative',
      zIndex: 1000,
    }}
  >
    <Menu inverted pointing secondary style={{ margin: 0, display: 'flex' }}>
      {!uuid && (
        <Menu.Item
          as={NavLink}
          exact
          to="/"
          name="устройства"
          style={{ textTransform: 'capitalize' }}
        />
      )}
      {match.params.id && (
        <Fragment>
          {!uuid && <Menu.Item icon="angle right" />}
          <Menu.Item
            as={NavLink}
            to={`/${match.params.id}`}
            name={formatDeviceId(currentDevice.motherboardID)}
            exact
            onClick={e => {
              if (match.path === '/:id') {
                e.preventDefault()
                toastr.info('😇', 'Have a nice day')
              }
            }}
            style={{ textTransform: 'capitalize' }}
          />
        </Fragment>
      )}
      {match.path === '/:id/keys' && (
        <Fragment>
          <Menu.Item icon="angle right" />
          <Menu.Item
            as={NavLink}
            to={`/${match.params.id}/keys`}
            name="Ключи"
            exact
            color="blue"
            style={{
              backgroundColor: 'transparent',
              textTransform: 'capitalize',
            }}
          />
        </Fragment>
      )}
      {match.path === '/:id/flats' && (
        <Fragment>
          <Menu.Item icon="angle right" />
          <Menu.Item
            as={NavLink}
            to={`/${match.params.id}/flats`}
            name="Квартиры"
            exact
            color="pink"
            style={{
              backgroundColor: 'transparent',
              textTransform: 'capitalize',
            }}
          />
        </Fragment>
      )}
      {match.path === '/:id/emergency' && (
        <Fragment>
          <Menu.Item icon="angle right" />
          <Menu.Item
            as={NavLink}
            to={`/${match.params.id}/emergency`}
            name="Оповещение о ЧС"
            exact
            color="red"
            style={{
              backgroundColor: 'transparent',
              textTransform: 'capitalize',
            }}
          />
        </Fragment>
      )}
      {match.path === '/:id/logs' && (
        <Fragment>
          <Menu.Item icon="angle right" />
          <Menu.Item
            as={NavLink}
            to={`/${match.params.id}/logs`}
            name="Логи панели"
            exact
            color="yellow"
            style={{
              backgroundColor: 'transparent',
              textTransform: 'capitalize',
            }}
          />
        </Fragment>
      )}
      {match.path === '/:id/camera_logs/:camera_id' && (
        <Fragment>
            <Menu.Item icon="angle right" />
            <Menu.Item
                as={NavLink}
                to={`/${match.params.id}/camera_logs/${match.params.camera_id}`}
                name="Логи камеры"
                exact
                color="yellow"
                style={{
                    backgroundColor: 'transparent',
                    textTransform: 'capitalize',
                }}
            />
        </Fragment>
      )}
      {match.path === '/:id/user_logs' && (
        <Fragment>
          <Menu.Item icon="angle right" />
          <Menu.Item
            as={NavLink}
            to={`/${match.params.id}/user_logs`}
            name="Пользовательские логи панели"
            exact
            color="yellow"
            style={{
              backgroundColor: 'transparent',
              textTransform: 'capitalize',
            }}
          />
        </Fragment>
      )}
      {match.path === '/:id/camera_user_logs/:camera_id' && (
        <Fragment>
            <Menu.Item icon="angle right" />
            <Menu.Item
                as={NavLink}
                to={`/${match.params.id}/camera_user_logs/${match.params.camera_id}`}
                name="Пользовательские логи камеры"
                exact
                color="yellow"
                style={{
                    backgroundColor: 'transparent',
                    textTransform: 'capitalize',
                }}
            />
        </Fragment>
      )}
      {match.path === '/:id/firmware' && (
        <Fragment>
          <Menu.Item icon="angle right" />
          <Menu.Item
            as={NavLink}
            to={`/${match.params.id}/firmware`}
            name="Прошивки"
            exact
            color="purple"
            style={{
              backgroundColor: 'transparent',
              textTransform: 'capitalize',
            }}
          />
        </Fragment>
      )}
      {match.path === '/:id/sounds' && (
        <Fragment>
          <Menu.Item icon="angle right" />
          <Menu.Item
            link
            as={NavLink}
            to={`/${match.params.id}/sounds`}
            name="Управление звуками"
            exact
            color="olive"
            style={{
              backgroundColor: 'transparent',
              textTransform: 'capitalize',
            }}
          />
        </Fragment>
      )}
      {match.path === '/:id/codes' && (
        <Fragment>
          <Menu.Item icon="angle right" />
          <Menu.Item
            link
            as={NavLink}
            to={`/${match.params.id}/codes`}
            name="Коды доступа"
            exact
            color="olive"
            style={{
              backgroundColor: 'transparent',
              textTransform: 'capitalize',
            }}
          />
        </Fragment>
      )}
      {match.path === '/:id/ble' && (
        <Fragment>
          <Menu.Item icon="angle right" />
          <Menu.Item
            link
            as={NavLink}
            to={`/${match.params.id}/ble`}
            name="BLE ключи"
            exact
            color="blue"
            style={{
              backgroundColor: 'transparent',
              textTransform: 'capitalize',
            }}
          />
        </Fragment>
      )}
      <Menu.Item
        as={NavLink}
        to="/login"
        onClick={logOut}
        name="Выход"
        style={{ marginLeft: 'auto', textTransform: 'capitalize' }}
      />
    </Menu>
  </Segment>
)

const mapStateToProps = ({ device, auth }) => ({
    currentDevice: device.currentDevice,
    uuid: auth.uuid,
})

export default connect(mapStateToProps, { logOut })(withRouter(Navbar))
