import styled from "styled-components";
import {amber} from "../../theme";
import wrapCategory from 'components/categories/wrapCategory'
import {Accordion, Container, Grid, Icon} from "semantic-ui-react";
import NetworkSettings from "../forms/NetworkSettings";

const Wrapper = styled.div`
    .title {
        &:hover {
            color: white !important;
            background: ${amber};
        }

        &.active {
            color: white !important;
            background: ${amber};
        }
    }
`

const Iframe = styled.iframe`
    height:150px;
    border: none;
    
    zoom: 1.5;
    -moz-transform: scale(1.2);
    -moz-transform-origin: 0 0;
    -o-transform: scale(1.2);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(1.2);
    -webkit-transform-origin: 0 0;`

const OnlineSquares = ({activeIndex, handleClick, uuid}) => {
    return (
        <Wrapper>
            <Accordion.Title
                active={activeIndex === 15}
                index={15}
                onClick={handleClick}
            >
                <Container className="category-title">
                    Онлайн за последние сутки
                    <Icon
                        name={`angle ${activeIndex === 15 ? 'down' : 'right'}`}
                    />
                </Container>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 15}>
                <Container textAlign={"center"}>
                            <Iframe src={`https://mission-control.sputnik.systems/embed/intercomOnlineSquares?w=500&intercomUUID=${uuid}`}/>

                </Container>
            </Accordion.Content>
        </Wrapper>
    )
}

export default wrapCategory(OnlineSquares)