import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Header, Icon, Button } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import { getDeviceInfo, getDeviceInfoShadow } from 'ducks/device'

const propNames = {
  "base.version": 'Общие настройки',
  "base.flats.version": 'Квартиры',
  "base.calls.spiAccount.version": 'Настройки  SIP',
  "base.socialInforming.version": 'Социальное информирование',
  "base.socialPolling.version": 'Опросы',
  // "base.socialInforming": 'Оповещения о ЧС',
  "base.soundVolumes.version": 'Настройки звука',
  "base.standardSounds.version": 'Стандартные звуки',
  "base.keys.keysVersion": 'Ключи',
  "base.keys.digitalKeysVersion": 'Цифровые коды',
}

const Wrapper = styled.div`
  & > *:nth-child(2) {
    padding: 0.625rem;
  }
`

const UnsyncWrapper = styled(Wrapper)`
  background: white;
  border: 2px solid black;
  border-radius: 10px;
`

const Title = styled.div`
  background: black;
  color: white;
  /* height: 1.625rem; */
  padding: 0.625rem;
  display: flex;
  justify-content: center;
`

class SyncStatus extends React.Component {
  state = {
    unsync: [],
  }

  componentDidMount() {
    this.checkSyncStatus()
    this._updateIntervalId = window.setInterval(() => {
      if (document.hidden) return
      this.updateDeviceInfo()
    }, 3000)
  }

  componentWillUnmount() {
    clearInterval(this._updateIntervalId)
  }

  updateDeviceInfo = async () => {
    const { match, history, dispatch } = this.props
    await Promise.all([
      dispatch(getDeviceInfo(match.params.id, history)),
    ])
    this.checkSyncStatus()
  }

  checkSyncStatus = () => {
    const { config, configShadow } = this.props.currentDevice

    const configVersions = extractVersionsFromConfig(config)
    const configShadowVersions = extractVersionsFromConfig(configShadow)

    const unsync = Object.keys(configVersions).map(
      (key) => configVersions[key] !== configShadowVersions[key] ? key : "",
    ).filter((e) => e !== "")
    this.setState({ unsync })
  }

  render() {
    const { unsync } = this.state

    return unsync.length > 0 ? (
      <UnsyncWrapper>
        <Title>Статус синхронизации</Title>
        <div>
          {unsync.map(e => (
            <Header.Subheader key={e}>
              <Icon
                name="refresh"
                style={{ marginRight: '.625rem', color: 'red' }}
              />
              {propNames[e]}
            </Header.Subheader>
          ))}
        </div>
      </UnsyncWrapper>
    ) : (
      <Button
        style={{
          marginLeft: 'auto',
          marginBottom: '0.625rem',
          background: 'black',
        }}
        icon
        labelPosition="left"
        color="black"
        circular
      >
        <Icon
          name="checkmark"
          style={{ marginRight: '.625rem' }}
          color="green"
        />
        Устройство синхронизировано
      </Button>
    )
  }
}

const mapStateToProps = ({ device: { currentDevice, shadowDevice } }) => ({
  currentDevice,
  shadowDevice,
})

const extractVersionsFromConfig = (conf) => {
  let res = {}
  const loopThroughObject = (o, pathPrefix) => {
    Object.keys(o).forEach((k) => {
      if (k.toLowerCase().indexOf("version") !== -1) {
        res[pathPrefix + "." + k] = o[k]
        return
      }
      if (typeof o[k] === 'object' && !Array.isArray(o[k]) && o[k] !== null) {
        loopThroughObject(o[k], pathPrefix !== "" ? pathPrefix + "." + k : k)
      }
    })
  }

  loopThroughObject(conf, "base")

  return res
}

export default connect(mapStateToProps)(withRouter(SyncStatus))
