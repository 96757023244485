import React, { useState } from 'react'
import { Table } from 'semantic-ui-react'
import { useMutation, useQuery } from '@apollo/client'
import { GET_VIRTUAL_FLATS } from 'gql/virtualFlats/query'
import { useParams } from 'react-router-dom'

import { toastr } from 'react-redux-toastr'
import Flat from './Flat'
import FlatResistanceModal from 'components/modals/FlatResistanceModal'
import { UPDATE_FLAT } from 'gql/flats/mutation'
import {SEND_START_CALL_EVENT} from "../../gql/events/mutation";
import {GET_COMMON_FLATS} from "../../gql/flats/query";

export default function VirtualFlats({ isIP, ID, ...props }) {
  const { id } = useParams()
  const [state, setState] = useState({
    flat: null,
    modal: false,
  })
  const { data } = useQuery(GET_VIRTUAL_FLATS, {
    variables: {
      input: {
        uuid: id,
      },
    },
  })
  const [updateFlats, info] = useMutation(UPDATE_FLAT, {
    refetchQueries: [
      {
        query: GET_VIRTUAL_FLATS,
        variables: {
          input: {
            uuid: id,
          },
        },
      },
    ],
  })

  const handleUpdateFlatInfo = async (flat) => {
    await updateFlats({
      variables: {
        input: {
          intercomID: flat.uuid,
          flats: [
            {
              flatNum: flat?.num,
              parameters: {
                analogSettings: {
                  alias: flat.analog_settings.alias,
                  blocked: flat.analog_settings?.blocked,
                },
                redirection: Boolean(flat.redirection),
                sipAccountContact: flat?.sip_contact,
                soundVol: flat?.sound_vol,
              },
            },
          ],
        },
      },
    }).then(() => {
      toastr.success('Успех')
    })
  }

  const [sendStartCallEventMutation] = useMutation(SEND_START_CALL_EVENT, {
    refetchQueries: [
      {
        query: GET_VIRTUAL_FLATS,
        variables: {
          input: {
            uuid: id,
          },
        },
      },
    ],
  });

  const handleStartCall = async (flat) => {
    await sendStartCallEventMutation({
      variables: {
        input: {
          intercom: {
            intercomID: flat.uuid,
          },
          flatNum: flat?.num,
        },
      },
    }).then((el) => {
      toastr.success('Успех')
    })
  };

  const modalTrigger = (flat) => {
    setState({ modal: true, flat: flat })
  }

  const closeModal = () => {
    setState({ modal: false, flat: null })
  }

  return (
    <React.Fragment>
      <FlatResistanceModal
        open={state.modal}
        flat={state.flat}
        id={ID}
        isVirtual={true}
        onClose={closeModal}
      />
      <Table selectable color="pink">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="100%" textAlign="center">
              Виртуальные квартиры
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell textAlign="center">Квартира</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Адрес SIP</Table.HeaderCell>
            {!isIP && (
              <>
                <Table.HeaderCell textAlign="center">Громкость звонка</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  Переадресация аналоговой линии
                </Table.HeaderCell>
              </>
            )}
            <Table.HeaderCell textAlign="center">
              Сделать тестовый звонок
            </Table.HeaderCell>
            <Table.HeaderCell />

          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data?.intercom?.configShadow?.flats?.newFlats?.edges?.map(({ node: flat }, i) => {
            return (
              <Flat
                isVirtual={true}
                key={flat.num}
                id={ID}
                flat={flat}
                updateFlatInfo={handleUpdateFlatInfo}
                startCall={handleStartCall}
                firstFlat={data?.intercom?.configShadow?.flats.firstFlat}
                lastFlat={data?.intercom?.configShadow?.flats.lastFlat}
                isIP={isIP}
                onClick={() => modalTrigger(flat)}
              />
            )
          })}
        </Table.Body>
      </Table>
    </React.Fragment>
  )
}
