import {gql} from "@apollo/client";

export const INTERCOM = gql`
    query Query($input: IntercomFilter!, $limitFlats: Int) {
        intercom(input: $input) {
            voiceText
            secretKey
            parents {
                uuid
            }
            motherboardID
            manufactureVersions {
                bleVersion
                hwVersion
                swVersion
            }
            isSipReg
            isOnline
            isActivated
            ipMode
            deviceName
            configShadow {
                timeZone
                standardSounds {
                    version
                    exit
                    invalidKey
                    externalButtonPress
                    button1Press
                    button2Press
                    button3Press
                    button4Press
                    button5Press
                    button6Press
                    button7Press
                    button8Press
                    button9Press
                    button0Press
                    buttonXPress
                    HandsetCall
                    guestPass
                    keyPass
                    polling
                    emergency
                    addKey
                    keyAdded
                }
                socialInforming {
                    version
                    start
                    end
                    url
                }
                socialPolling {
                    version
                    start
                    end
                    url
                }
                flats {
                    firstFlat
                    lastFlat
                    flatOffset
                    flatRange
                    defaultThresholdCall
                    defaultThresholdDoor
                    version
                    newFlats(limit: $limitFlats) {
                        edges {
                            node {
                                num
                                redirectionBool
                                sipAccountContact
                                soundVol
                                analogSettings {
                                    alias
                                    blocked
                                    thresholdCall
                                    thresholdDoor
                                }
                            }
                        }
                    }
                }
                calls {
                    sipAccount {
                        server
                        username
                        login
                        password
                        permanentSipConnection
                        incomingCall
                        callPassword
                        dtmfOpenDoor
                        version
                    }
                    optionalButton {
                        sipCallUri
                        useMainSipCreds
                        sipLogin
                        sipPassword
                        sipServer
                        sipUsername
                        redirection {
                            flat
                            redirect
                        }
                    }
                    flatDialingTimeTimeout
                    flatCallTimeTimeout
                    generalSipBlocked
                }
                network {
                    ip
                    gateway
                    subnet
                    dns
                    mac
                    minPort
                    maxPort
                }
                light
                infrared
                soundVolumes {
                    general
                    speakHandsetTx
                    speakLoudspeaker
                    speakSIP
                    version
                }
                commutator {
                    commutatorType
                    lineAdcValues {
                        one
                        three
                        two
                    }
                }
                doorOpenDurations {
                    general
                    social
                    emergency
                    bluetooth
                }
                soundProcessingConfig
                lockNoNc
                externalInterfaceDoorCtrl
                version
                keys {
                    collectingKeyStatus
                    keysVersion
                    digitalKeysVersion
                }
            }
            companyUUID
            collectingKeysState
            clusterPrefixes {
                prefix
                firstFlat
                lastFlat
                voiceText
            }
            children {
                uuid
            }
            camera {
                agent {
                    agentID
                    agentSalt
                    agentKey
                    agentSerial
                    agentModel
                }
                serverStreamInfo {
                    serverEmbedURL
                    serverStreamURL
                    serverThumbnailsURL
                    serverVideoThumbnailsURL
                }
                isOnline
                model
                uuid
            }
            config {
                version
                calls {
                    sipAccount {
                        version
                    }
                }
                flats {
                    version
                }
                keys {
                    digitalKeysVersion
                    keysVersion
                }
                socialPolling {
                    version
                }
                socialInforming {
                    version
                }
                soundVolumes {
                    version
                }
                standardSounds {
                    version
                }
            }
            uuid
        }
    }
`