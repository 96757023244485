import React from 'react'
import styled from 'styled-components'
import PanelStatus from './PanelStatus'
import CameraStatus from "./CameraStatus";
import Sync from './Sync'

const Sticky = styled.div`
  position: fixed;
  bottom: 1.625rem;
  right: 1.625rem;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
`

export default () => (
  <Sticky>
    <PanelStatus />
    <CameraStatus />
    <Sync />
  </Sticky>
)
