import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Label,
  Grid,
  Form,
  Input,
  Segment,
  Checkbox,
  Button,
  Header,
  Dropdown,
} from 'semantic-ui-react'
import { updateDoorOpenTimers } from 'ducks/deviceActions'

const center = {
  display: 'flex',
  justifyContent: 'center',
}

const comOptions = [
  {
    key: 0,
    text: 'NC', //NC Нормально закрыто
    value: 0,
  },
  {
    key: 1,
    text: 'NO', //NO Нормально открыто
    value: 1,
  },
]

class DoorTimer extends Component {
  state = {
    general: this.props.data.general,
    social_message: this.props.data.social,
    emergency_message: this.props.data.emergency,
    bluetooth: this.props.data.bluetooth,
    modified: false,
    door1: comOptions[0],
    door2: comOptions[0],
    lock_no_nc: 0,
    external_interface_door_ctrl: this.props.external_interface_door_ctrl,
  }

  onSubmit = (e) => {
    this.props.updateDoorOpenTimers({
      id: this.props.id,
      door_open_time_general: this.state.general,
      door_open_time_social: this.state.social_message,
      door_open_time_emergency: this.state.emergency_message,
      door_open_time_bt: this.state.bluetooth,
      lock_no_nc: this.state.lock_no_nc,
      external_interface_door_ctrl: this.state.external_interface_door_ctrl,
    })
    this.setState({
      modified: false,
    })
  }

  componentDidMount() {
    this.reverseLockTypeManager()
  }

  magnetControl = (e, { value, name }) => {
    const result = comOptions.filter((el) => el.value === value)[0]
    this.setState({ [name]: result }, () => this.lockTypeManager())
  }

  lockTypeManager = () => {
    const door1 = this.state.door1.value
    const door2 = this.state.door2.value

    let result
    if (door1 === 0 && door2 === 0) result = 0
    if (door1 === 1 && door2 === 0) result = 1
    if (door1 === 0 && door2 === 1) result = 2
    if (door1 === 1 && door2 === 1) result = 3

    this.setState({ lock_no_nc: result, modified: true })
  }

  reverseLockTypeManager = () => {
    const value = this.props.lock_no_nc
    this.setState({ lock_no_nc: value })
    switch (value) {
      case 0:
        return this.setState({
          door1: comOptions[0],
          door2: comOptions[0],
        })
      case 1:
        return this.setState({
          door1: comOptions[1],
          door2: comOptions[0],
        })

      case 2:
        return this.setState({
          door1: comOptions[0],
          door2: comOptions[1],
        })

      case 3:
        return this.setState({
          door1: comOptions[1],
          door2: comOptions[1],
        })

      default:
        return this.setState({
          door1: comOptions[0],
          door2: comOptions[0],
        })
    }
  }

  onChange = (e) =>
    this.setState({ [e.target.name]: e.target.value, modified: true })

  render() {
    const {
      general,
      social_message,
      emergency_message,
      bluetooth,
      external_interface_door_ctrl,
    } = this.state
    
    return (
      <Segment {...this.props}>
        <Header>Время открытия двери</Header>
        <div style={{ ...center, flexDirection: 'column', flex: 1 }}>
          <Form>
            <Form.Field>
              <label>Общее</label>
              <Input
                placeholder="Общее"
                size="mini"
                name="general"
                type="number"
                value={general}
                // defaultValue={}
                onChange={this.onChange}
              />
            </Form.Field>
            <Form.Field>
              <label>При социальном сообщении</label>
              <Input
                placeholder="При социальном сообщении"
                size="mini"
                name="social_message"
                type="number"
                value={social_message}
                // defaultValue={}
                onChange={this.onChange}
              />
            </Form.Field>
            <Form.Field>
              <label>При экстренном сообщении</label>
              <Input
                placeholder="При экстренном сообщении"
                size="mini"
                name="emergency_message"
                type="number"
                value={emergency_message}
                // defaultValue={}
                onChange={this.onChange}
              />
            </Form.Field>
            <Form.Field>
              <label>При bluetooth подключении</label>
              <Input
                placeholder="При bluetooth подключении"
                size="mini"
                name="bluetooth"
                type="number"
                value={bluetooth}
                onChange={this.onChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Основная дверь</label>
              <Dropdown
                value={this.state.door1.value}
                onChange={this.magnetControl}
                name="door1"
                size="mini"
                selection
                options={comOptions}
                style={{
                  lineHeight: '1.21428571em',
                  padding: '0.67857143em 1em',
                  fontSize: '0.78em',
                }}
              />
            </Form.Field>
            <Form.Field>
              <label>Дополнительная дверь</label>
              <Dropdown
                value={this.state.door2.value}
                onChange={this.magnetControl}
                name="door2"
                size="mini"
                selection
                options={comOptions}
                style={{
                  lineHeight: '1.21428571em',
                  padding: '0.67857143em 1em',
                  fontSize: '0.78em',
                }}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                label="Wiegand для основной двери"
                toggle
                onClick={() =>
                  this.setState({
                    external_interface_door_ctrl: !!external_interface_door_ctrl
                      ? 0
                      : 1,
                    modified: true,
                  })
                }
                checked={!!external_interface_door_ctrl}
              />
            </Form.Field>
            <Form.Button
              circular
              style={{
                margin: '1rem auto',
                marginBottom: 0,
                marginTop: 'auto',
              }}
              color="black"
              onClick={this.onSubmit}
              className="update-button"
              disabled={!this.state.modified}>
              Обновить
            </Form.Button>
          </Form>
        </div>
      </Segment>
    )
  }
}

const mapStateToProps = ({ device }) => ({
  data: device.currentDevice.configShadow?.doorOpenDurations,
  id: device.currentDevice.uuid,
  lock_no_nc: device.currentDevice.configShadow?.lockNoNc,
  external_interface_door_ctrl: device.currentDevice.configShadow?.externalInterfaceDoorCtrl,
})

export default connect(mapStateToProps, { updateDoorOpenTimers })(DoorTimer)
