import React, { Component, Fragment } from 'react'
import {
    Dropdown,
    Grid,
    Container,
    Loader,
    Table,
    Button,
    Message,
    Input,
} from 'semantic-ui-react'
import { connect } from 'react-redux'
import LogEntry from 'components/listings/LogEntry'
import {
    getCameraDeviceLogs,
    clearCurrentDeviceInfo, getDeviceInfo,
} from 'ducks/device'
import { clearDeviceStatus,
    getCameraNetInfo,
} from 'ducks/deviceActions'
import styled from 'styled-components'
import Navbar from 'components/global/Navbar'
import GoToTop from 'components/global/GoToTop'
import EmptyLoad from 'components/global/EmptyLoad'

const DevicePageStyled = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const options = [
    { key: '1', text: '10', value: 10 },
    { key: '2', text: '25', value: 25 },
    { key: '3', text: '50', value: 50 },
    { key: '4', text: '75', value: 75 },
]

class CameraLogsPage extends Component {
    state = {
        logsQty: options[0].value,
        showButtons: false,
        error: false,
        activePage: 1,
    }

    componentDidMount() {
        const { match } = this.props
        this.props.getDeviceInfo(match.params.id)
        this.props.getCameraDeviceLogs(
            match.params.camera_id,
            this.state.logsQty,
            this.state.activePage
        )
        window.addEventListener('keydown', this.showButtons)
    }

    componentWillUnmount() {
        this.props.dispatch(clearDeviceStatus())
        clearInterval(this._updateIntervalId)
        this.props.dispatch(clearCurrentDeviceInfo())
        window.removeEventListener('keydown', this.showButtons)
    }

    static getDerivedStateFromProps(props, state) {
        if (props.error) {
            props.dispatch(clearDeviceStatus())
            return { error: true }
        }
        return state
    }

    onChange = (e, { value }) =>
        this.setState({
            logsQty: value,
        })

    showButtons = (e) => {
        if (e.keyCode === 16) {
            this.setState({
                showButtons: !this.state.showButtons,
            })
            console.log(this.state.showButtons)
            console.log(e.keyCode)
        }
    }

    onClick = (e) =>
        this.props.getCameraDeviceLogs(
            this.props.match.params.camera_id,
            this.state.logsQty,
            this.state.activePage
        )

    nextPage = (e) => {
        this.props.getCameraDeviceLogs(
            this.props.match.params.camera_id,
            this.state.logsQty,
            this.state.activePage + 1
        )
        this.setState({ activePage: this.state.activePage + 1 })
    }

    prevPage = (e) => {
        if (this.state.activePage < 1) {
            return
        }
        this.props.getCameraDeviceLogs(
            this.props.match.params.camera_id,
            this.state.logsQty,
            this.state.activePage - 1
        )
        this.setState({ activePage: this.state.activePage - 1 })
    }

    getCameraNetInfo = () => {
        this.props.getCameraNetInfo({
            deviceUuid: this.props.match.params.camera_id,
            logsQty: this.state.logsQty,
            activePage: this.state.activePage,
        })
    }

    render() {
        const { logs } = this.props

        if (this.state.error)
            return (
                <Fragment>
                    <Navbar />
                    <DevicePageStyled>
                        <Message error size="large">
                            Произошла ошибка при загрузке логов камеры.
                            Убедитесь, что панель зарегистрирована.
                        </Message>
                    </DevicePageStyled>
                </Fragment>
            )

        if (Array.isArray(logs.collection) && !logs.collection.length)
            return <EmptyLoad />

        if (!logs.collection || logs.collection.length < 1) {
            return (
                <DevicePageStyled>
                    <div className="loading">
                        <Loader active />
                    </div>
                </DevicePageStyled>
            )
        }

        return (
            <div tabIndex="0">
                <Navbar />
                <GoToTop />

                <Container style={{ marginTop: '4.625rem' }}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column
                                style={{ padding: 0 }}
                                // textAlign="right"
                                // floated="right"
                            >
                                <div
                                    style={{ display: 'flex', justifyContent: 'space-between' }}
                                >
                                    <div>
                                        <Dropdown
                                            placeholder="Количество логов"
                                            selection
                                            onChange={this.onChange}
                                            value={this.state.logsQty}
                                            options={options}
                                            floated="right"
                                            style={{ marginRight: '.625rem' }}
                                        />
                                        <Button
                                            color="yellow"
                                            // floated="right"
                                            onClick={this.onClick}
                                            circular
                                        >
                                            Обновить данные
                                        </Button>
                                    </div>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        {this.state.showButtons && (
                            <Fragment>
                                <Button
                                    content="Запросить сетевые настройки"
                                    color="grey"
                                    floated="left"
                                    onClick={this.getCameraNetInfo}
                                    circular
                                />
                            </Fragment>
                        )}
                        <Grid.Row>
                            {logs.isFetching ? (
                                <div style={{ height: 400 }}>
                                    <Loader active />
                                </div>
                            ) : (
                                <Table selectable color="yellow">
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell textAlign="center">
                                                Дата
                                            </Table.HeaderCell>
                                            <Table.HeaderCell textAlign="center">
                                                Модуль
                                            </Table.HeaderCell>
                                            <Table.HeaderCell textAlign="center">
                                                Действие
                                            </Table.HeaderCell>
                                            <Table.HeaderCell textAlign="center">
                                                Детали
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {logs.collection.map((entry, i) => (
                                            <LogEntry entry={entry} key={i} />
                                        ))}
                                    </Table.Body>

                                    <Container
                                        style={{
                                            position: 'fixed',
                                            bottom: 20,
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: '100%',
                                                background: 'transparent',
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                            }}
                                        >
                                            <Table.Row>
                                                <Table.HeaderCell colSpan="4">
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'flex-end',
                                                        }}
                                                    >
                                                        <Button
                                                            color="yellow"
                                                            circular
                                                            labelPosition="left"
                                                            icon="left chevron"
                                                            content="Пред."
                                                            disabled={this.state.activePage === 1}
                                                            onClick={this.prevPage}
                                                        />
                                                        <Button
                                                            color="yellow"
                                                            circular
                                                            labelPosition="right"
                                                            icon="right chevron"
                                                            content="След."
                                                            onClick={this.nextPage}
                                                        />
                                                    </div>
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </div>
                                    </Container>
                                </Table>
                            )}
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    logs: state.device.cameraLogs,
    error: state.device.error,
})

export default connect(mapStateToProps, {
    getCameraDeviceLogs,
    getDeviceInfo,
    getCameraNetInfo,
})(CameraLogsPage)
