import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import App from 'components/App'
import registerServiceWorker from './serviceWorker'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import configureStore from 'store/configureStore'
import ReduxToastr, { toastr } from 'react-redux-toastr'
import 'theme/semantic.css'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import 'index.css'
import {
  ApolloProvider,
} from '@apollo/client'
import apolloClient from "./gql/client";
const store = configureStore()

//2125129189
const Root = () => (
  <Provider store={store}>
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <Fragment>
          <App />
          <ReduxToastr preventDuplicates position="top-right" progressBar />
        </Fragment>
      </BrowserRouter>
    </ApolloProvider>
  </Provider>
)

ReactDOM.render(<Root />, document.getElementById('root'))

const showUpdateToast = (action) =>
  toastr.info('Доступна новая версия', 'Нажмите для обновления', {
    onToastrClick: action,
  })

