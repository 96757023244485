import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container, Table, Loader, Button, Modal, Header } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'

import {getDeviceInfo, clearCurrentDeviceInfo} from 'ducks/device'
import {
  clearDeviceStatus,
  lineTest,
  deleteAllFlats,
} from 'ducks/deviceActions'
import Flat from 'components/listings/Flat'
import styled from 'styled-components'
import Navbar from 'components/global/Navbar'
import { toastr } from 'react-redux-toastr'
import AddVirtualFlatModal from 'components/modals/AddVirtualFlatModal'
import FlatsGeneral from 'components/forms/FlatsGeneral'
import FlatResistanceModal from 'components/modals/FlatResistanceModal'
import VirtualFlats from 'components/listings/VirtualFlats'
import DeleteVirtualFlatModal from 'components/modals/DeleteVirtualFlatModal'
import CommonFlats from 'components/listings/CommonFlats'

const Wrapper = styled.div`
  i {
    cursor: pointer;
  }
`

class FlatsPage extends Component {
  state = {
    addVirtualFlatModal: false,
    confirmModalOpen: false,
    flatModalOpen: false,
    deleteVirtualFlatModal: false,
  }

  componentDidMount() {
    const { match, history } = this.props
    this.props.getDeviceInfo(match.params.id, history)
  }

  componentWillUnmount() {
    this.props.dispatch(clearCurrentDeviceInfo())
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.success) {
      toastr.success('Успех!', nextProps.success)
      setTimeout(() => nextProps.clearDeviceStatus(), 1000)
    }
    if (nextProps.error) {
      toastr.error('Что-то пошло не так...', nextProps.error)
      setTimeout(() => nextProps.clearDeviceStatus(), 1000)
    }
    return prevState
  }

  handleModalClose = () => this.setState({ confirmModalOpen: false })

  toggleAddVirtualFlatModal = () => {
    this.setState((prev) => ({
      addVirtualFlatModal: !prev.addVirtualFlatModal,
    }))
  }

  toggleDeleteVirtualModal = () => {
    this.setState((prev) => ({
      deleteVirtualFlatModal: !prev.deleteVirtualFlatModal,
    }))
  }

  modalTrigger = (flat) => {
    this.setState({ flatModalOpen: true, flat })
  }

  render() {
    const { match, flats, lineTest, pendingLineTest } = this.props

    if (!match.params.id) {
      return <Redirect to="/" />
    }

    if (!flats) {
      return (
        <div style={{ width: '100vw', heigth: '100%' }}>
          <Loader active />
        </div>
      )
    }

    return (
      <Wrapper>
        <Navbar />
        <Container style={{ margin: '4.625rem auto' }}>
          <FlatsGeneral />
          <Button color="pink" onClick={this.toggleAddVirtualFlatModal}>
            Добавить квартиру
          </Button>
          <Button color="red" onClick={this.toggleDeleteVirtualModal}>
            Удалить виртуальную квартиру
          </Button>

          <VirtualFlats
            isIP={this.props.isIP}
            pendingLineTest={pendingLineTest}
            ID={match.params.id}
          />

          {this.props.isIP && (
            <Button onClick={() => this.setState({ confirmModalOpen: true })}>
              Удалить все квартиры
            </Button>
          )}

          <CommonFlats
            isIP={this.props.isIP}
            pendingLineTest={pendingLineTest}
            lineTest={lineTest}
            ID={match.params.id}
          />
        </Container>
        <Modal
          open={this.state.confirmModalOpen}
          onClose={this.handleModalClose}
          basic
          size="small"
        >
          <Header icon="warning" content="Внимание" />
          <Modal.Content>
            <h3>Вы действительно хотите удалить все квартиры?</h3>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.handleModalClose} inverted circular>
              Не удалять
            </Button>
            <Button
              onClick={() => {
                this.props.deleteAllFlats(this.props.match.params.id)
                this.handleModalClose()
              }}
              color="red"
              circular
            >
              Удалить все квартиры
            </Button>
          </Modal.Actions>
        </Modal>
        <AddVirtualFlatModal
          open={this.state.addVirtualFlatModal}
          onClose={this.toggleAddVirtualFlatModal}
        />
        <FlatResistanceModal
          open={this.state.flatModalOpen}
          onClose={() => this.setState({ flatModalOpen: false, flat: null })}
          flat={this.state.flat}
        />
        <DeleteVirtualFlatModal
          open={this.state.deleteVirtualFlatModal}
          onClose={this.toggleDeleteVirtualModal}
        />
      </Wrapper>
    )
  }
}


const mapStateToProps = ({device: {currentDevice}, deviceActions}) => ({
  flats: currentDevice?.configShadow?.flats.newFlats.edges.map(e => e.node),
  checksum: currentDevice?.configShadow?.flats.version,
  error: deviceActions.error,
  success: deviceActions.success,
  isIP: currentDevice?.ipMode,
  pendingLineTest: deviceActions.pendingLineTest,
})

export default connect(mapStateToProps, {
  getDeviceInfo,
  clearDeviceStatus,
  lineTest,
  deleteAllFlats,
})(FlatsPage)
