import React, {Component, Fragment, useEffect, useState} from 'react'
import {
  Dropdown,
  Grid,
  Container,
  Loader,
  Table,
  Button,
  Message,
  Input,
} from 'semantic-ui-react'
import {connect, useDispatch, useSelector} from 'react-redux'
import LogEntry from 'components/listings/LogEntry'
import {
  getDeviceUserLogs,
  getDeviceInfo,
  clearCurrentDeviceInfo,
} from 'ducks/device'
import { clearDeviceStatus, updateTimeZone } from 'ducks/deviceActions'
import styled from 'styled-components'
import Navbar from 'components/global/Navbar'
import GoToTop from 'components/global/GoToTop'
import EmptyLoad from 'components/global/EmptyLoad'

const DevicePageStyled = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const options = [
  { key: '1', text: '10', value: 10 },
  { key: '2', text: '25', value: 25 },
  { key: '3', text: '50', value: 50 },
  { key: '4', text: '75', value: 75 },
]

const CameraUserLogsPage = ({...props}) => {
  const [state, setState] = useState({
    logsQty: options[0].value,
    error: false,
    activePage: 1,
    zoneValue: null,
  })

  const {userLogs, currentDevice} = useSelector((state) => state.device)

  const dispatch = useDispatch()

  useEffect(() => {
    props.dispatch(getDeviceInfo(props.match.params.id))
    dispatch(getDeviceUserLogs(
        props.match.params.camera_id,
        state.logsQty,
        state.activePage))
  }, []);

  const onChange = (e, { value }) =>
    setState({
      logsQty: value,
    })

  const onClick = (e) =>
    props.getDeviceUserLogs(
      props.match.params.camera_id,
      state.logsQty,
      state.activePage
    )

  const changeZoneValue = (e) => {
    setState({ ...state, zoneValue: e.target.value })
  }

  const handleUpdateTimeZone = () => {
    let result = state.zoneValue
    if (state.zoneValue > 0) {
      result = '+' + state.zoneValue.toString()
    }
    dispatch(updateTimeZone(props.match.params.id, result))
  }

  const nextPage = (e) => {
    dispatch(getDeviceUserLogs(
      props.match.params.camera_id,
      state.logsQty,
      state.activePage + 1
    ))
    setState({ ...state, activePage: state.activePage + 1 })
  }

  const prevPage = (e) => {
    if (state.activePage < 1) {
      return
    }
    dispatch(getDeviceUserLogs(
      props.match.params.camera_id,
      state.logsQty,
      state.activePage - 1
    ))
    setState({ ...state, activePage: state.activePage - 1 })
  }

  if (state.error)
    return (
      <Fragment>
        <Navbar />
        <DevicePageStyled>
          <Message error size="large">
            Произошла ошибка при загрузке пользовательских логов панели.
            Убедитесь, что панель зарегистрирована.
          </Message>
        </DevicePageStyled>
      </Fragment>
    )

    if (Array.isArray(userLogs.collection) && !userLogs.collection.length) {
      return (<EmptyLoad/>)
    }

    if (!userLogs.collection || userLogs.collection.length < 1) {
      return (
        <DevicePageStyled>
          <div className="loading">
            <Loader active />
          </div>
        </DevicePageStyled>
      )
    }

    return (
      <div tabIndex="0">
        <Navbar />
        <GoToTop />

        <Container style={{ marginTop: '4.625rem' }}>
          <Grid>
            <Grid.Row>
              <Grid.Column
                style={{ padding: 0 }}
                // textAlign="right"
                // floated="right"
              >
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>
                    <Input
                      value={state.zoneValue}
                      type="number"
                      onChange={changeZoneValue}
                      placeholder={props.timeZone}
                      style={{ marginRight: '.625rem' }}
                    />
                    <Button
                      color="yellow"
                      onClick={handleUpdateTimeZone}
                      circular
                    >
                      Обновить таймзону
                    </Button>
                  </div>

                  <div>
                    <Dropdown
                      placeholder="Количество логов"
                      selection
                      onChange={onChange}
                      value={state.logsQty}
                      options={options}
                      floated="right"
                      style={{ marginRight: '.625rem' }}
                    />
                    <Button
                      color="yellow"
                      // floated="right"
                      onClick={onClick}
                      circular
                    >
                      Обновить данные
                    </Button>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              {userLogs.isFetching ? (
                <div style={{ height: 400 }}>
                  <Loader active />
                </div>
              ) : (
                <Table selectable color="yellow">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell textAlign="center">
                        Дата
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                        Модуль
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                        Действие
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                        Детали
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {userLogs.collection.map((entry, i) => (
                      <LogEntry entry={entry} key={i} />
                    ))}
                  </Table.Body>

                  <Container
                    style={{
                      position: 'fixed',
                      bottom: 20,
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        background: 'transparent',
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Table.Row>
                        <Table.HeaderCell colSpan="4">
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Button
                              color="yellow"
                              circular
                              labelPosition="left"
                              icon="left chevron"
                              content="Пред."
                              disabled={state.activePage === 1}
                              onClick={prevPage}
                            />
                            <Button
                              color="yellow"
                              circular
                              labelPosition="right"
                              icon="right chevron"
                              content="След."
                              disabled={userLogs.collection.length < state.logsQty}
                              onClick={nextPage}
                            />
                          </div>
                        </Table.HeaderCell>
                      </Table.Row>
                    </div>
                  </Container>
                </Table>
              )}
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    )
}

export default CameraUserLogsPage