import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Redirect, withRouter} from 'react-router-dom'
import styled from 'styled-components'
import {Accordion, Grid, Loader} from 'semantic-ui-react'
import {toastr} from 'react-redux-toastr'

import {getDeviceInfo,} from 'ducks/device'
import {
    openDoor,
    openSecondDoor,
    resetDeviceCamera,
    resetIntercom,
    startEmergency,
    updateDeviceSounds,
    uploadFirmware,
} from 'ducks/deviceActions'

import DevicePageHeader from './DevicePageHeader'
import Poll from 'components/categories/Poll'
import Social from 'components/categories/Social'
import Emergency from 'components/categories/Emergency'
import GeneralSettings from 'components/categories/GeneralSettings'
import FlatsListing from 'components/categories/FlatsListing'
import KeysListing from 'components/categories/KeysListing'
import AccessCodesListing from 'components/categories/AccessCodesListing'
import Firmware from 'components/categories/Firmware'
import LogsListing from 'components/categories/LogsListing'
import UserLogsListing from 'components/categories/UserLogsListing'
import CameraLogsListing from 'components/categories/CameraLogsListing'
import CameraUserLogsListing from 'components/categories/CameraUserLogsListing'
import StandardSounds from 'components/categories/StandardSounds'
import SIP from 'components/categories/SIP'
import Navbar from 'components/global/Navbar'
import Network from 'components/categories/Network'
import SOS from 'components/categories/SOS'
import Stickies from '../global/Stickies'

import Cluster from 'components/categories/Cluster'
import ResetSettings from 'components/categories/ResetSettings'
import OnlineSquares from "../categories/OnlineSquares";

const DevicePageStyled = styled.div`
    min-height: 100vh;
`

const copyToClipboard = (content, notification) => {
    // copy to clipboard
    const el = document.createElement('textarea')
    el.value = content
    document.body && document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body && document.body.removeChild(el)
    toastr.info(notification)
}

const DevicePage = ({match, history}) => {
    const {currentDevice, sounds} = useSelector(state => state.device)
    const dispatch = useDispatch()
    
    const uuid = match.params.id.trim()

    const [state, setState] = useState({
        activeIndex: -1,
        isOpen: false,
        doorDuration: 5,
        secondDoorOpen: false,
    })

    useEffect(() => {
        dispatch(getDeviceInfo(uuid, history))
    }, [])


    const handleClick = (e, titleProps) => {
        const {index} = titleProps
        const {activeIndex} = state
        const newIndex = activeIndex === index ? -1 : index

        setState({...state, activeIndex: newIndex})
    }

    const handleDoorDurationChange = (e, {value}) =>
        setState({...state, doorDuration: value})

    const onStartEmergency = (e) => {
        dispatch(startEmergency(uuid))
    }

    const onResetIntercom = (e) => {
        dispatch(resetIntercom(uuid))
    }

    const onResetDeviceCamera = () => {
        dispatch(resetDeviceCamera(uuid))
    }

    const onOpen = (e) => {
        dispatch(openDoor({
            id: uuid,
            duration: state.doorDuration,
        }))
        setState({...state, isOpen: true})
        setTimeout(
            () => setState({...state, isOpen: false}),
            state.doorDuration * 1000
        )
    }
    const onSecondDoorOpen = (e) => {
        dispatch(openSecondDoor({
            id: match.params.id,
            duration: state.doorDuration,
        }))
        setState({...state, secondDoorOpen: true})
        setTimeout(
            () => setState({...state, secondDoorOpen: false}),
            state.doorDuration * 1000
        )
    }

    const updateSounds = (soundType, startTime, endTime, file) => {
        dispatch(updateDeviceSounds({
            id: uuid,
            soundType,
            startTime,
            endTime,
            file,
        }))
    }

    // static getDerivedStateFromProps(nextProps, prevState) {
    //   if (nextProps.success) {
    //     toastr.success('Успех!', nextProps.success)
    //     setTimeout(() => nextProps.clearDeviceStatus(), 1000)
    //   }
    //   if (nextProps.error && nextProps.error) {
    //     toastr.error('Что-то пошло не так...', nextProps.error)
    //     setTimeout(() => nextProps.clearDeviceStatus(), 1000)
    //   }
    //   return prevState
    // }
    const copyStreamUrl = () => {
        const {currentDevice} = props
        if (!currentDevice) return

        // copy to clipboard
        copyToClipboard(
            currentDevice?.camera.serverStreamInfo.serverStreamURL,
            'Ссылка на камеру была успешно скопирована'
        )
    }

    const copyDeviceSerial = () => {
        if (!currentDevice) return

        // copy to clipboard
        copyToClipboard(
            currentDevice?.motherboardID,
            'Серийник был успешно скопирован'
        )
    }

    const copyCameraDeviceSerial = () => {
        if (!currentDevice) return

        // copy to clipboard
        copyToClipboard(
            currentDevice?.camera.agent.agentSerial,
            'Серийник камеры был успешно скопирован'
        )
    }

    const uuidRe = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i

    if (!uuidRe.test(uuid)) {
        return <Redirect to="/"/>
    }

    if (currentDevice == null) {
        return (
            <DevicePageStyled>
                <div className="loading">
                    <Loader active/>
                </div>
            </DevicePageStyled>
        )
    }

    return (
        <DevicePageStyled>
            <Navbar/>

            <DevicePageHeader
                currentDevice={currentDevice}
                onOpen={onOpen}
                onSecondDoorOpen={onSecondDoorOpen}
                onResetIntercom={onResetIntercom}
                isOpen={state.isOpen}
                onCopyStreamClick={copyStreamUrl}
                onCopyDeviceSerial={copyDeviceSerial}
                onCopyCameraDeviceSerial={copyCameraDeviceSerial}
                onResetDeviceCamera={onResetDeviceCamera}
                doorDuration={state.doorDuration}
                handleDoorDurationChange={handleDoorDurationChange}
                secondDoorOpen={state.secondDoorOpen}
            />

            <Grid>
                <Grid.Row>
                    <Grid.Column verticalAlign="middle">
                        <Accordion fluid>
                            <GeneralSettings
                                activeIndex={state.activeIndex}
                                handleClick={handleClick}
                            />

                            <Cluster
                                activeIndex={state.activeIndex}
                                handleClick={handleClick}
                            ></Cluster>
                            <Social
                                activeIndex={state.activeIndex}
                                handleClick={handleClick}
                                data={currentDevice?.configShadow.socialInforming}
                                sounds={sounds}
                                onUpdateSound={updateSounds}
                            />
                            <Poll
                                activeIndex={state.activeIndex}
                                handleClick={handleClick}
                                data={currentDevice?.configShadow.socialPolling}
                                onUpdateSound={updateSounds}
                            />
                            <Emergency
                                activeIndex={state.activeIndex}
                                handleClick={handleClick}
                                startEmergency={onStartEmergency}
                                id={match.params.id}
                            />
                            <SOS
                                activeIndex={state.activeIndex}
                                handleClick={handleClick}
                                data={currentDevice?.configShadow.calls.optionalButton}
                            />
                            <SIP
                                activeIndex={state.activeIndex}
                                handleClick={handleClick}
                                data={currentDevice?.configShadow.calls.sipAccount}
                                device={currentDevice}
                            />
                            <Firmware
                                activeIndex={state.activeIndex}
                                handleClick={handleClick}
                                uploadFirmware={uploadFirmware}
                                id={match.params.id}
                            />
                            <ResetSettings
                                activeIndex={state.activeIndex}
                                handleClick={handleClick}
                            />
                            <StandardSounds
                                activeIndex={state.activeIndex}
                                handleClick={handleClick}
                                id={match.params.id}
                            />
                            <Network
                                activeIndex={state.activeIndex}
                                handleClick={handleClick}
                            />
                            <OnlineSquares
                                activeIndex={state.activeIndex}
                                handleClick={handleClick}
                                uuid={match.params.id}
                            />
                            <FlatsListing id={match.params.id}/>
                            <KeysListing id={match.params.id}/>
                            <AccessCodesListing
                                id={match.params.id}
                            ></AccessCodesListing>
                            <LogsListing id={match.params.id}/>
                            <UserLogsListing id={match.params.id}/>
                            <CameraLogsListing id={match.params.id} camera_id={currentDevice?.camera?.uuid || ""}/>
                            <CameraUserLogsListing id={match.params.id}
                                                   camera_id={currentDevice?.camera?.uuid || ""}/>
                            <Stickies/>
                        </Accordion>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </DevicePageStyled>
    )
}

export default withRouter(DevicePage)
